import React from 'react';
import PropTypes from 'prop-types';

import InfoBar from './InfoBar';
import SiteTitle from '../SiteTitle';

import layoutStyles from './layout.module.css';

const SiteHeader = ( { links } ) => {
	return (
		<>
			<header className={ layoutStyles.siteHeader }>
				<div className="wrapper">
					<a href="/">
						<SiteTitle
							className={ layoutStyles.siteTitle }
							size="2.5rem"
						/>
					</a>
				</div>

				<InfoBar
					links={ links }
				/>
			</header>
		</>
	);
};

SiteHeader.propTypes = { links: PropTypes.array.isRequired };

export default SiteHeader;
