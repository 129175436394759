import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import layoutStyles from './layout.module.css';

const SiteNavigation = ( { links } ) => {
	const mapLinks = () => links.map( ( { text, to }, i ) => (
		<Fragment key={ i }>
			{ i > 0 ? <span>&nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;</span> : null }
			{ to
				? <Link to={ to } style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>{ text }</Link>
				: <span style={{ display: 'inline-block', textSpaceCollapse: 'nowrap' }}>{ text }</span>
			}
		</Fragment>
	) );

	return (
		<div className={ layoutStyles.siteNavigation }>
			<div className={ layoutStyles.scrollingWrapper }>
				{ mapLinks() }
			</div>
		</div>
	);
};

SiteNavigation.propTypes = { links: PropTypes.array.isRequired };

export default SiteNavigation;
