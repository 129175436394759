import React from 'react';
import PropTypes from 'prop-types';

import SiteHeader from './SiteHeader';

const Layout = ( { children, links } ) => {
	return (
		<>
			<SiteHeader links={ links } />
			<main>
				{ children }
			</main>
		</>
	);
};

Layout.defaultProps = {
	breadcrumbs: [],
	links: []
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	links: PropTypes.array
};

export default Layout;
