import React from 'react';
import PropTypes from 'prop-types';

/* import Breadcrumbs from './Breadcrumbs';
   import Filter from './Filter'; */
import SiteNavigation from './SiteNavigation';

import layoutStyles from './layout.module.css';

const InfoBar = ( { links } ) => {
	return (
		<header className={ layoutStyles.infoBar }>
			<div className="wrapper">
				<SiteNavigation links={ links } />
			</div>
		</header>
	);
};

InfoBar.propTypes = { links: PropTypes.array.isRequired };

export default InfoBar;
